export enum EVENTS {
  UserLogoutSuccess = 'UserLogoutSuccess',
  UserLogoutClick = 'UserLogoutClick',
  CheckoutPlanOptionSelection = 'CheckoutPlanOptionSelection',
  CheckoutDiscountApplyClick = 'CheckoutDiscountApplyClick',
  CheckoutGstInvoiceClick = 'CheckoutGstInvoiceClick',
  CheckoutContinueClick = 'CheckoutContinueClick',
  CheckoutSaveAndMakePaymentClick = 'CheckoutSaveAndMakePaymentClick',
  SubscriptionSuccessConfirmation = 'SubscriptionSuccessConfirmation',
  PartnerCodeApplied = 'PartnerCodeApplied',
  HomeStartTrialClick = 'HomeStartTrialClick',
  LoginSuccessPageView = 'LoginSuccessPageView',
  MWebLoginPageView = 'MWebLoginPageView',
  LoginFailed = 'LoginFailed',
  HomeStartTrialClicked = 'HomeStartTrialClick',
  MWebCallUsClick = 'MWebCallUsClick'
}

/* export enum FirebaseEvents {
  SyncInitiated = 'SyncInitiated',

  SyncInProgress = 'SyncInProgress',
  SyncFailed = 'SyncFailed',
  SyncSuccess = 'SyncSuccess',

  SyncIndeterminate = 'SyncIndeterminate',
  SyncApiFailed = 'SyncPushApiFailed',
  SyncTerminated = 'SyncTerminated',

  OnboardingBookSyncInitiated = 'OnboardingBookSyncInitiated',
  OnboardingBookSyncSuccess = 'OnboardingBookSyncSuccess',
  OnboardingBookSyncFailed = 'OnboardingBookSyncFailed',

  BookSyncPushStarted = 'BookSyncPushStarted',
  BookSyncPushFailed = 'BookSyncPushFailed',
  BookSyncPullStarted = 'BookSyncPullStarted',
  BookSyncPullFailed = 'BookSyncPullFailed',

  DatabaseTransactionError = 'DatabaseTransactionError',

  PageUnloadStart = 'PageUnloadStart',
  PageUnloadDbTransactionAbortStart = 'PageUnloadDbTransactionAbortStart',
  PageUnloadDbTransactionAbortEnd = 'PageUnloadDbTransactionAbortEnd',
}
 */
