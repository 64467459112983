// helpers
// import { captureException } from '../sentry'

// constants
// import { SENTRY_TAG, TAGS } from '../sentry/constants' //'src/helpers/sentry/constants'
export const defaultHeaders: Record<string, string | boolean> = {
  'x-kb-app-locale': 'en',
  'x-kb-app-version': '000100', // Change this to read from env files as needed
  'x-kb-app-name': 'khatabook', // Change this to read from env files as needed
  'x-kb-new-auth': false,
  'x-kb-platform': 'web',
  'x-tenant-name': 'KB',
  'x-kb-client-type': 'mobile-web' // mobile-web | desktop | ipad | android
};

export class KBError extends Error {
  resStatus = 0;

  constructor (message: string, resStatus: number) {
    super(message);
    this.resStatus = resStatus;
  }
}

let globalHeaders: RequestInit['headers'] = {
};

/**
 * helper function to set headers for all requests via KBFetch
 * like Authorization headers / token
 **/
export const setFetchHeaders = (headers: RequestInit['headers']) => {
  globalHeaders = {
    ...headers
  };
};

// export const getFetchHeaders = () => globalHeaders;

/**
 * Just a decorator over fetch with some defaults and logging
 * @param apiIdentfier - Unique Indentifer for sentry logging
 * @param url - Request URL
 * @param options - Fetch Request Options
 */

const KBFetch = async (
  apiIdentfier: string,
  url: string,
  {
    method = 'GET',
    mode = 'cors',
    credentials = 'same-origin',
    headers = {
    },
    ...rest
  }: RequestInit = {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
    }
  }
) => {
  return await fetch(url, {
    method,
    mode,
    credentials,
    // adding global headers
    headers: {
      ...globalHeaders, ...headers
    },
    ...rest
  })
    .then(async (response) => {
      // TODO - add middleware to handle logged out status
      // non valid response log in sentry;
      if (response.status === 200 || response.status === 201) {
        return await Promise.resolve(response);
      }
      if (response.status >= 400) {
        /* captureException(new Error(`API invalid status: ${response.status}`), {
                    tags: {
                        [TAGS.API]: apiIdentfier,
                        module: SENTRY_TAG.API_HELPER,
                    },
                    extra: {
                        url,
                    },
                }) */
        const res = await response.json();
        const err = new KBError(res, response.status);
        return await Promise.reject(err);
      }
    })
    .then(async (resp) => await resp?.json())
    .catch(async (error: KBError) => {
      // log exceptions in sentry
      const e = error.message;
      /* captureException(error, {
                tags: {
                    [TAGS.API]: apiIdentfier,
                    module: SENTRY_TAG.API_HELPER,
                },
                extra: {
                    url,
                },
            }) */

      // consumer should handle rejection
      return await Promise.reject(e);
    });
};

export default KBFetch;
