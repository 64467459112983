import { h } from 'preact';
import { useEffect } from "preact/hooks";
import useStyles from "./style";
import RefundInfoList from "./components/refund-list/refund-list";
import Banner from "./components/banner";
import PaymentSelector from "./components/payment-selector";
import ShutdownInfo from "./components/shutdownInfo";
import useRefundActions from "./refund-actions";
import ControlWrapper from "src/components/common/control-wrapper";
import { toIndianCurrency } from "src/utils/conversions";
import FooterSunsetMobile from 'src/assets/icons/svg/footer-sunset-mobile';
import { route } from 'preact-router';

const Refund = () => {
  const styles = useStyles();
  const {
    view,
    totalRefundAmount,
    ordersList,
    isLoading,
    saveRefundPreferences,
    paymentMode
  } = useRefundActions();

  useEffect(() => {
    if (!isLoading && totalRefundAmount === 0) {
      route('/download');
    }
  }, [isLoading, totalRefundAmount]);

  useEffect(() => {
    const ele = document.getElementsByClassName(
      "super-container"
    )[0] as HTMLElement;
    if (!ele) return;
    ele.style.width = "100vw";
    document.body.style.overflow = "scroll";

    return () => {
      ele.style.overflow = "hidden";
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  // if (isLoading) return <Loader type="page" className="loader" />;

  return (
    <div className={styles.container}>
      {/* <Header /> */}
      {/* <Toolbar /> */}
      <div className={styles.body}>
        <div className={styles.bodyHeader}>
          <span>
            We are <span style={{
              color: "#DC343B"
            }}
                   >discontinuing</span>{" "}
            <span style={{
              color: "#DC343B"
            }}
            >Bills</span> and{" "}
            <span style={{
              color: "#DC343B"
            }}
            >Inventory</span> on Khatabook
            Desktop
          </span>
        </div>

        <Banner />
        <ControlWrapper shouldShow={view === "DEFAULT"}>
          <div
            className={styles.knowMoreBtn}
            onClick={() => scrollToSection("shutdown-info")}
            role={"presentation"}
          >
            Know More
          </div>
        </ControlWrapper>

        <ControlWrapper shouldShow={view === "DEFAULT"}>
          <div className={styles.caption}>
            <span>Claim your refund for unused days of subscription</span>
          </div>

          <div className={styles.formContainer}>
            <RefundInfoList
              ordersList={ordersList || []}
              totalRefundAmount={totalRefundAmount || 0}
            />
            <PaymentSelector
              totalRefundAmount={totalRefundAmount}
              saveRefundPreferences={saveRefundPreferences}
            />
          </div>
        </ControlWrapper>
        <ControlWrapper shouldShow={view === "INITIATED"}>
          <div className={styles.initiated}>
            <span className={styles.initiatedTitle}>
              Refund request of{" "}
              <span style={{
                color: "#31A54C"
              }}
              >
                {toIndianCurrency(totalRefundAmount)}
              </span>{" "}
              is getting processed
            </span>
            <div style={{
              fontWeight: "700",
              fontSize: 12
            }}
            >
              Please wait for  <span style={{
                color: "#DC343B"
              }}
              >{paymentMode === 'SOURCE' ? '7' : '15'} days </span>
              to get your money back
            </div>
          </div>
        </ControlWrapper>
        <ControlWrapper shouldShow={view === "REFUNDED"}>
          <div className={styles.initiated}>
            <span className={styles.initiatedTitle}>
              Refund request of{" "}
              <span style={{
                color: "#31A54C"
              }}
              >
                {toIndianCurrency(totalRefundAmount)}
              </span>{" "}
              has been processed
            </span>
          </div>
        </ControlWrapper>
      </div>
      <ShutdownInfo />
      <FooterSunsetMobile />
    </div>
  );
};

export default Refund;
