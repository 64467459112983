import { h } from 'preact';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  button: {
    padding: "12px 24px",
    fontSize: "14px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    display: "inline-block",
    width: "100%",
    borderRadius: 8,
    fontWeight: 700
  },
  activeButton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1558a4"
    }
  },
  disabledButton: {
    backgroundColor: "#CCCCCC",
    color: "#666666",
    cursor: "not-allowed"
  }
}));

const CustomButton = ({
  title, onClick, disabled
}: any) => {
  const styles = useStyles();

  return (
    <button
      className={`${styles.button} ${
        disabled ? styles.disabledButton : styles.activeButton
      }`}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default CustomButton;
