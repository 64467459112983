import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  orderList: {
  },
  refundInfoContainer: {
    border: "1px solid #DDDDDD",
    borderRadius: 12,
    // width: 511,
    paddingBottom: 12
  },

  refundInfoContainerHeader: {
    // borderBottom: "1px solid #DDDDDD",
    padding: "12px 0",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainTitle: {
    fontSize: 16,
    fontWeight: 700,
    "& .green": {
      color: "#31A54C"
    }
  },
  refundInfoContainerBody: {
    fontSize: 12,
    borderTop: "1px dashed #DDDDDD",
    padding: 20
  },
  row: {
    display: "flex",
    paddingBottom: 10,
    justifyContent: "space-between"
  },
  label: {
  },
  value: {
  },
  bold: {
    fontWeight: 700
  }
}));

export default useStyles;
