/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class RudderstackAnalytics {
  private readonly isEnabled: boolean = false;
  private readonly key: string | undefined;
  private readonly dataPlaneUrl: string | undefined;

  constructor() {
    this.isEnabled = process.env.ENV_IS_RUDDERSTACK_ENABLED === 'true';
    this.key = process.env.ENV_RUDDERSTACK_KEY;
    this.dataPlaneUrl = process.env.ENV_RUDDERSTACK_DATA_PLANE_URL;
  }

  private hasLoaded(): boolean {
    return !!window.rudderanalytics;
  }

  initialize() {
    console.log('Initializing rudderstack for analytics');
    if (!this.isEnabled) {
      console.log('Rudderstack not enabled, skipping initialisation');
      return;
    }
    if (!this.key || !this.dataPlaneUrl) {
      console.error(
        'Either key or dataplane URL is missing for rudderstack, not initialising'
      );
      return;
    }

    if (this.hasLoaded()) {
      window.console &&
        console.warn &&
        console.warn('Rudder analytics already loaded. returning.');
      return;
    }
    const e = (window.rudderanalytics = window.rudderanalytics || []);
    e.methods = [
      'load',
      'page',
      'track',
      'identify',
      'alias',
      'group',
      'ready',
      'reset',
      'getAnonymousId',
      'setAnonymousId',
      'getUserId',
      'getUserTraits',
      'getGroupId',
      'getGroupTraits'
    ];
    e.factory = function (t: any) {
      return function (...restParams: any[]) {
        const r = Array.prototype.slice.call(restParams);
        return r.unshift(t), e.push(r), e;
      };
    };
    for (let t = 0; t < e.methods.length; t++) {
      const r = e.methods[t];
      e[r] = e.factory(r);
    }
    e.loadJS = function (e: any, t: any) {
      const r = document.createElement('script');
      r.type = 'text/javascript';
      r.async = !0;
      r.src = 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js';
      const a = document.getElementsByTagName('script')[0];
      a.parentNode?.insertBefore(r, a);
    };
    e.loadJS();
    e.load(this.key, this.dataPlaneUrl);
    e.page();
  }

  reset() {
    window.rudderanalytics.reset(true);
  }

  identifyUser(userId: string, traits: object = {
  }) {
    if (!this.isEnabled || !this.hasLoaded()) {
      return;
    }
    window.rudderanalytics.identify(userId, traits);
  }

  getUserTraits() {
    return window.rudderanalytics.getUserTraits();
  }

  trackEvent(event: string, data: any) {
    if (!this.isEnabled || !this.hasLoaded()) {
      return;
    }
    window.rudderanalytics.track(event, data);
  }
}
