import { h } from 'preact';
import 'purecss/build/pure.css';
import './style';
import { Route, Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Logout from 'src/routes/Logout';
import PaymentSuccess from 'src/routes/PaymentSuccess';
import AuthProvider from 'src/context/AuthProvider';
import { Header } from 'src/components/common/Header';
import AnalyticsProvider from './context/Analytics';
import { isInvokedByAndroid } from './routes/Subscribe/utils';
import Refund from './routes/refund/refund-page';
import DownloadKB from './routes/downloadKB';

const App = () => (
  <AuthProvider>
    <AnalyticsProvider>
      {isInvokedByAndroid() ? null : <Header />}
      <Router>
        <Route path='/'
          component={Refund}
        />
        <Route path='/download' component={DownloadKB} />

        <Route path='/logout'
          component={Logout}
        />
        <Route path='/purchase/order-status'
          component={PaymentSuccess}
        />
      </Router>
    </AnalyticsProvider>
  </AuthProvider>
);

export default App;
