import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 20
  },
  header: {
    height: 60,
    display: "flex",
    position: "sticky"
  },

  body: {
    // width: 900,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  bodyHeader: {
    // width: 677,
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    padding: "0 20px"
  },
  formContainer: {
    padding: "0 20px"
  },

  knowMoreBtn: {
    background: "#DC343B",
    color: "white",
    marginTop: 20,
    padding: "10px 16px",
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer"
  },
  caption: {
    margin: "24px 0 16px 0",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center"
  },
  footerImage: {
  },
  initiated: {
    backgroundColor: "#FBF9F2",
    padding: "20px  10px",
    width: "fit-content",
    margin: "44px 0 100px 0",
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  initiatedTitle: {
    fontSize: 15,
    fontWeight: 700,
    textAlign: 'center'
  }
}));

export default useStyles;
