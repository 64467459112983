import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  banner: {
    backgroundColor: "#FEF8E1",
    // width: "fit-content",
    borderRadius: "100px",
    padding: 10,
    fontSize: 12,
    margin: "10px 0",
    maxWidth: 211,
    textAlign: "center"
  }
}));

export default useStyles;
