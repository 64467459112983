import { PriceVisibleToUser } from "src/models/orders";

export const roundTo2Decimal = (num: number) =>
  Math.round((num + 0.00001) * 100) / 100;

export const toAtLeast2DigitStr = (val: number) => toAtLeastNDigitStr(val, 2);

export const toAtLeastNDigitStr = (val: number, n: number) => {
  const diff = val.toString().length - n;
  return (diff < 0 ? Array(-diff).fill('0').join('') : '') + val;
};

export const insertIndianStyleComma = (n?: number | null): string => {
  if (n === null || n === undefined) return '';
  const isNegative = n < 0;

  if (isNegative) {
    n = Math.abs(n);
  }

  n = roundTo2Decimal(n);
  let str = '';
  // Take care of decimal
  const decimal = roundTo2Decimal(n - Math.floor(n));
  if (decimal !== 0) {
    str = decimal.toString().slice(1);
  }
  // Take care of the int part
  n = Math.floor(n);
  // Take care of the thousands

  str =
    toAtLeastNDigitStr(
      Number(n.toString().slice(-3)),
      Math.min(n.toString().length, 3)
    ) + str;

  n = Math.floor(n / 1000);

  if (n === 0) {
    if (isNegative) {
      str = `-${str}`;
    }
    return str;
  }
  // Take care of >999.99
  while (n >= 100) {
    const newPart = toAtLeast2DigitStr(n % 100);
    n = Math.floor(n / 100);
    str = `${newPart},${str}`;
  }

  let numberWithCommas = n >= 0 ? `${n},${str}` : str;
  if (isNegative) {
    numberWithCommas = `-${numberWithCommas}`;
  }
  return numberWithCommas;
};

const combineBillingPeriodAndUnit = (period: number, unit: string) => {
  const pluralSuffix = period > 1 ? 's' : '';
  return `${period} ${unit}${pluralSuffix}`;
};

const getPriceToDisplay = (priceVisible: PriceVisibleToUser) => {
  const amountInRupee = Math.floor((priceVisible.price || 0) / 100);
  const commaSeparated = insertIndianStyleComma(amountInRupee);
  return `₹${commaSeparated} ${
    !priceVisible.isTaxIncluded ? '+ 18% Tax' : ''
  }`;
};

const isAfterNow = (date: string) => {
  const nowTS = Date.now();
  const dateTS = convertDateToTS(new Date(date));
  return nowTS > dateTS;
};

const convertDateToTS = (date: Date) => {
  return date.getTime();
};

export const parseFloatSafely = (v: string): number | null => {
  try {
    const p = parseFloat(v);
    return p;
  } catch (e) {
    return null;
  }
};

export const getFloatValue = (n: string): number =>
  !isNaN(parseFloat(n)) ? parseFloat(n) : 0;

export const getRoundedOff = (n: string | number, t = 2) =>
  getFloatValue(getFloatValue(n as string).toFixed(t));

const toIndianCurrency = (num: number, includeDecimal = false) => {
  if (num === 0) {
    return '₹';
  }

  const curr = num.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 4
  });
  if (
    !includeDecimal &&
    curr.substring(curr.length - 3, curr.length) === '.00'
  ) {
    return curr.substring(0, curr.length - 3);
  }
  return curr;
};

const toIndianCurrencyWithZero = (num: string | number) => {
  if (num === null || num === 0) {
    return '₹0.0';
  }
  return toIndianCurrency(getRoundedOff(num));
};

export {
  getPriceToDisplay, combineBillingPeriodAndUnit, isAfterNow, toIndianCurrency, toIndianCurrencyWithZero
};
