export enum ROUTES {
  GET_BENEFIT = 'GET_BENEFIT',
  PULL_SUBSCRIPTION_ORDERS = 'PULL_SUBSCRIPTION_ORDERS',
  ACTIVATE_TRIAL = 'ACTIVATE_TRIAL',
  GET_BULK_PRODUCTS = 'GET_BULK_PRODUCTS',
  GET_MWEB_BULK_PRODUCTS = 'GET_MWEB_BULK_PRODUCTS',
  GET_NON_PRE_APPLIED_DISC = 'GET_NON_PRE_APPLIED_DISC',
  LAUNCH_ORDER = 'LAUNCH_ORDER',
  REFERRAL_CODE = 'REFERRAL_CODE',
  CHECKOUT_AND_CALCULATIONS = 'CHECKOUT_AND_CALCULATIONS',
  COMPLETE_ORDER = 'COMPLETE_ORDER',
  GET_ORDER_HISTORY = 'GET_ORDER_HISTORY',
  INVOICE_PARTY = 'INVOICE_PARTY',
  GET_REFUND_DETAILS = 'GET_REFUND_DETAILS',
  POST_REFUND_DETAILS = 'POST_REFUND_DETAILS',
  GET_REFUNABLE_ORDERS = 'GET_REFUNABLE_ORDERS',
}

export const ROUTE_URLS = {
  [ROUTES.GET_BENEFIT]: '/kb-api/v3/benefits?include_plan_info=true',
  [ROUTES.PULL_SUBSCRIPTION_ORDERS]: '/kb-api/v1/orders/sync',
  [ROUTES.ACTIVATE_TRIAL]: '/kb-api/v1/free-trial-activate',
  [ROUTES.GET_BULK_PRODUCTS]: '/kb-api/v1/product/bulk-get',
  [ROUTES.GET_MWEB_BULK_PRODUCTS]: '/kb-api/v1/product/bulk-get/mweb',
  [ROUTES.GET_NON_PRE_APPLIED_DISC]: '/kb-api/v1/discount/get-by-coupon',
  [ROUTES.LAUNCH_ORDER]: '/kb-api/v1/purchase/launch',
  [ROUTES.REFERRAL_CODE]: '/kb-api/v1/referral',
  [ROUTES.CHECKOUT_AND_CALCULATIONS]: '/kb-api/v1/purchase/checkout',
  [ROUTES.COMPLETE_ORDER]: '/kb-api/v1/purchase/',
  [ROUTES.GET_ORDER_HISTORY]: '/kb-api/orders/sync?server_seq=0&limit=25',
  [ROUTES.INVOICE_PARTY]: '/kb-api/v1/invoice-party',
  [ROUTES.GET_REFUND_DETAILS]: "/v1/saas_refunds/refund_details",
  [ROUTES.POST_REFUND_DETAILS]: "/v1/saas_refunds/refund_details",
  [ROUTES.GET_REFUNABLE_ORDERS]: "/kb-api/v1/refundable-orders"
};

export const BASE_URL = process.env.ENV_API_ENDPOINT; // point to a value from env configuration

export const getURL = (route: ROUTES) => {
  return `${BASE_URL}${ROUTE_URLS[route]}`;
};
