import { h } from 'preact';
import { toIndianCurrency } from "src/utils/conversions";
import useStyles from "./styles";

interface RefundInfoCardType {
  startAndEndDate: string;
  lastInvoiceAmount: string;
  subscriptionPeriod: number;
  unusedDays: string;
  unusedAmount: number;
}
const RefundInfoCard = ({
  startAndEndDate,
  lastInvoiceAmount,
  subscriptionPeriod,
  unusedDays,
  unusedAmount
}: RefundInfoCardType) => {
  const styles = useStyles();
  return (
    <div className={styles.refundInfoContainerBody}>
      <div className={styles.row}>
        <span className={`${styles.label} ${styles.bold}`}>
          {startAndEndDate}
        </span>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Last Invoice Amount (A)</div>
        <div className={styles.value}>{lastInvoiceAmount}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Subscription period (B)</div>
        <div className={styles.value}>{subscriptionPeriod}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Unused days (C)</div>
        <div className={styles.value}>{unusedDays}</div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.label} ${styles.bold}`}>Unused amount</div>
        <div className={`${styles.label} ${styles.bold}`}>{unusedAmount}</div>
      </div>
    </div>
  );
};

interface RefundInfoList {
  totalRefundAmount: number;
  ordersList: any[];
}

const RefundInfoList = ({
  totalRefundAmount,
  ordersList = []
}: RefundInfoList) => {
  const styles = useStyles();
  return (
    <div className={styles.refundInfoContainer}>
      <div className={styles.refundInfoContainerHeader}>
        <span className={styles.mainTitle}>
          Your refund Amount:{" "}
          <span className='green'>{toIndianCurrency(totalRefundAmount)}</span>
        </span>
        <div style={{
          fontSize: 12, fontWeight: 500
        }}
        >
          How refund amount is calculated?
        </div>
        <div style={{
          fontSize: 10, fontWeight: 500
        }}
        >
          {/* eslint-disable-next-line no-irregular-whitespace */}
          Refund calculation (₹) = (A/B​) × C
        </div>
      </div>
      <div className={styles.orderList}>
        {(ordersList || []).map((o: any, i: number) => (
          <RefundInfoCard key={i} {...o} />
        ))}
      </div>
    </div>
  );
};
export default RefundInfoList;
