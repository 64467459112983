import { h } from 'preact';

const FooterSunsetMobile = () => {
  return (
    <svg width='375' height='98' viewBox='0 0 375 98' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_51_4390)'>
        <g style='mix-blend-mode:luminosity' opacity='0.5' clip-path='url(#clip1_51_4390)'>
          <path d='M309.315 134.458L269.991 131.048C269.208 126.587 267.229 115.686 267.229 115.686L273.543 113.579L272.136 99.2691L274.234 98.4282L279.219 96.4342L282.204 95.2407L282.259 95.3505L284.695 100.125L284.016 94.5558L280.941 69.3046L291.518 61.4963L291.828 61.6235L308.026 68.2267L308.826 109.438L309.312 134.461L309.315 134.458Z' fill='#CCE9E6' />
          <path d='M274.196 98.8473C274.196 98.8473 274.104 96.8446 276.578 96.1222C279.051 95.3968 279.676 97.0353 279.676 97.0353L274.196 98.8473Z' fill='#CCE9E6' />
          <path d='M291.903 68.9406L282.502 73.0644L282.169 70.3942L291.18 65.2648L291.903 68.9406Z' fill='#BFE4E0' />
          <path d='M293.769 84.0978L284.155 86.5051L283.86 84.1181L293.05 80.422L293.769 84.0978Z' fill='#BFE4E0' />
          <path d='M294.374 93.4808L285.432 96.3186L285.288 95.1655L294.209 92.1746L294.374 93.4808Z' fill='#AFD5D0' />
          <path d='M286.577 125.07L283.811 124.214L282.169 95.3911L282.262 95.3478L284.701 100.122L286.577 125.07Z' fill='#A3D9D4' />
          <path d='M308.829 109.435C307.901 109.112 307.115 108.719 307.115 108.719L300.746 107.225L298.948 107.817L298.44 105.089L291.833 61.6177L308.032 68.2209L308.832 109.433L308.829 109.435Z' fill='#A3D9D4' />
          <path d='M283.71 94.0529L281.655 94.4171L280.667 87.5653H283.028L283.71 94.0529Z' fill='#CCE9E6' />
          <path d='M270.922 118.653L267.674 118.228L265.81 63.7L266.888 65.6303L270.922 118.653Z' fill='#87BCB6' />
          <path d='M262.728 72.6534L271.115 74.3948L271.128 74.3325L262.741 72.5912L262.728 72.6534Z' fill='#87BCB6' />
          <path d='M262.567 72.3229L270.954 74.0642L271.015 73.7728L262.628 72.0314L262.567 72.3229Z' fill='#87BCB6' />
          <path d='M262.4 71.433L271.081 73.0802L270.957 73.6726L262.57 71.9329L262.4 71.433Z' fill='#87BCB6' />
          <path d='M270.379 69.1705L263.296 68.7804L263.278 69.2659L270.151 69.526L270.379 69.1705Z' fill='#87BCB6' />
          <path d='M269.928 68.6503L262.842 68.2601L262.825 68.7456L269.697 69.0057L269.928 68.6503Z' fill='#87BCB6' />
          <path d='M265.256 71.9388L263.825 71.6412L263.932 71.1239L265.325 71.6065L265.256 71.9388Z' fill='#87BCB6' />
          <path d='M277.811 98.2404L272.364 99.8413L272.115 99.5668L277.684 97.7L277.811 98.2404Z' fill='#87BCB6' />
        </g>
        <g style='mix-blend-mode:luminosity' opacity='0.5' clip-path='url(#clip2_51_4390)'>
          <path d='M261.215 155.259L221.892 151.849C221.109 147.387 219.129 136.486 219.129 136.486L225.443 134.38L224.036 120.069L226.134 119.228L231.119 117.234L234.105 116.041L234.159 116.151L236.596 120.925L235.916 115.356L232.842 90.1048L243.419 82.2965L243.728 82.4237L259.926 89.0269L260.727 130.239L261.212 155.261L261.215 155.259Z' fill='#CCE9E6' />
          <path d='M243.803 89.7405L234.402 93.8643L234.07 91.1941L243.081 86.0647L243.803 89.7405Z' fill='#BFE4E0' />
          <path d='M260.73 130.236C259.802 129.912 259.016 129.519 259.016 129.519L252.646 128.025L250.849 128.617L250.34 125.889L243.734 82.4177L259.932 89.021L260.732 130.233L260.73 130.236Z' fill='#A3D9D4' />
          <path d='M222.823 139.453L219.575 139.028L217.711 84.5L218.789 86.4304L222.823 139.453Z' fill='#87BCB6' />
          <path d='M214.629 93.4535L223.016 95.1948L223.029 95.1326L214.642 93.3912L214.629 93.4535Z' fill='#87BCB6' />
          <path d='M214.468 93.1228L222.855 94.8641L222.915 94.5727L214.529 92.8314L214.468 93.1228Z' fill='#87BCB6' />
          <path d='M214.301 92.233L222.982 93.8802L222.858 94.4726L214.471 92.733L214.301 92.233Z' fill='#87BCB6' />
          <path d='M222.28 89.9704L215.197 89.5803L215.179 90.0658L222.051 90.3259L222.28 89.9704Z' fill='#87BCB6' />
          <path d='M221.829 89.4503L214.743 89.0602L214.726 89.5457L221.598 89.8058L221.829 89.4503Z' fill='#87BCB6' />
          <path d='M217.156 92.7388L215.726 92.4411L215.833 91.9238L217.225 92.4064L217.156 92.7388Z' fill='#87BCB6' />
        </g>
        <g style='mix-blend-mode:luminosity' opacity='0.5' clip-path='url(#clip3_51_4390)'>
          <path d='M352.214 124.059L312.891 120.649C312.108 116.187 310.128 105.286 310.128 105.286L316.443 103.18L315.036 88.8692L317.134 88.0283L322.119 86.0343L325.104 84.8408L325.159 84.9506L327.595 89.7246L326.916 84.1559L323.841 58.9047L334.418 51.0964L334.727 51.2236L350.926 57.8268L351.726 99.0385L352.212 124.061L352.214 124.059Z' fill='#CCE9E6' />
          <path d='M323.844 91.5856L317.096 93.3426L316.929 90.5655L323.622 88.6755L323.844 91.5856Z' fill='#BFE4E0' />
          <path d='M317.096 88.4473C317.096 88.4473 317.004 86.4447 319.477 85.7223C321.951 84.9969 322.575 86.6354 322.575 86.6354L317.096 88.4473Z' fill='#CCE9E6' />
          <path d='M334.803 58.5406L325.402 62.6644L325.07 59.9942L334.081 54.8647L334.803 58.5406Z' fill='#BFE4E0' />
          <path d='M336.669 73.6979L327.055 76.1052L326.76 73.7182L335.95 70.0221L336.669 73.6979Z' fill='#BFE4E0' />
          <path d='M337.273 83.0809L328.332 85.9187L328.188 84.7656L337.109 81.7747L337.273 83.0809Z' fill='#AFD5D0' />
          <path d='M329.476 114.67L326.711 113.814L325.069 84.9912L325.162 84.9479L327.601 89.7218L329.476 114.67Z' fill='#A3D9D4' />
          <path d='M351.729 99.0354C350.801 98.7118 350.015 98.3188 350.015 98.3188L343.646 96.8247L341.848 97.4171L341.34 94.6892L334.733 51.2177L350.931 57.8209L351.732 99.0325L351.729 99.0354Z' fill='#A3D9D4' />
          <path d='M326.609 83.653L324.555 84.0172L323.566 77.1654H325.927L326.609 83.653Z' fill='#CCE9E6' />
          <path d='M313.666 112.571L312.593 89.6609H312.122V91.1H310.368V90.9844H308.027C308.027 91.493 308.438 91.9034 308.946 91.9034L310.368 91.6549V91.3312H312.122V112.574H313.666V112.571Z' fill='#6E9DB1' />
          <path d='M306.716 111.361L305.788 94.3138H305.433V95.4004H304.106V95.3137H302.337C302.337 95.6981 302.65 96.0073 303.031 96.0073L304.106 95.8194V95.5738H305.433V111.384L306.716 111.361Z' fill='#A3D9D4' />
          <path d='M320.812 118.603L317.564 118.178L315.7 63.6499L316.778 65.5803L320.812 118.603Z' fill='#87BCB6' />
          <path d='M312.619 72.6033L321.006 74.3446L321.019 74.2824L312.632 72.541L312.619 72.6033Z' fill='#87BCB6' />
          <path d='M312.458 72.2727L320.845 74.014L320.905 73.7226L312.518 71.9813L312.458 72.2727Z' fill='#87BCB6' />
          <path d='M312.291 71.3829L320.972 73.0301L320.848 73.6225L312.461 71.8829L312.291 71.3829Z' fill='#87BCB6' />
          <path d='M320.269 69.1203L313.186 68.7302L313.169 69.2157L320.041 69.4758L320.269 69.1203Z' fill='#87BCB6' />
          <path d='M319.819 68.6002L312.733 68.2101L312.715 68.6956L319.588 68.9557L319.819 68.6002Z' fill='#87BCB6' />
          <path d='M315.146 71.8888L313.715 71.5911L313.822 71.0739L315.215 71.5565L315.146 71.8888Z' fill='#87BCB6' />
          <path d='M320.712 87.8403L315.264 89.4413L315.016 89.1668L320.585 87.2999L320.712 87.8403Z' fill='#87BCB6' />
          <path d='M320.432 91.1271L314.738 92.4531L314.864 92.9935L320.558 91.6675L320.432 91.1271Z' fill='#87BCB6' />
          <path d='M338.796 67.3808L335.591 50.3396L341.608 51.5764L341.582 51.6949L335.739 50.4928L338.915 67.3606L338.796 67.3808Z' fill='#FFF3C4' />
        </g>
        <g style='mix-blend-mode:luminosity'>
          <path d='M261.176 111.31L260.104 88.4H259.633V89.8392H257.878V89.7236H255.538C255.538 90.2322 255.948 90.6425 256.457 90.6425L257.878 90.394V90.0703H259.633V111.313H261.176V111.31Z' fill='#6E9DB1' />
        </g>
      </g>
      <g clip-path='url(#clip4_51_4390)'>
        <g style='mix-blend-mode:luminosity' opacity='0.5' clip-path='url(#clip5_51_4390)'>
          <path d='M-4.77742 116.259L34.5458 112.849C35.329 108.387 37.3086 97.4863 37.3086 97.4863L30.9941 95.3797L32.4015 81.0693L30.3034 80.2284L25.3183 78.2344L22.333 77.0409L22.2781 77.1507L19.8419 81.9247L20.521 76.356L23.5959 51.1048L13.0188 43.2965L12.7095 43.4237L-3.48852 50.0269L-4.28902 91.2386L-4.77453 116.261L-4.77742 116.259Z' fill='#CCE9E6' />
          <path d='M23.5932 83.7857L30.3412 85.5427L30.5088 82.7656L23.8157 80.8756L23.5932 83.7857Z' fill='#BFE4E0' />
          <path d='M30.3409 80.6473C30.3409 80.6473 30.4333 78.6447 27.9596 77.9222C25.4858 77.1969 24.8616 78.8354 24.8616 78.8354L30.3409 80.6473Z' fill='#CCE9E6' />
          <path d='M12.6344 50.7407L22.0353 54.8644L22.3677 52.1942L13.3569 47.0648L12.6344 50.7407Z' fill='#BFE4E0' />
          <path d='M10.7677 65.8976L20.3825 68.3049L20.6772 65.9179L11.4873 62.2218L10.7677 65.8976Z' fill='#BFE4E0' />
          <path d='M10.1636 75.2809L19.105 78.1187L19.2495 76.9657L10.3283 73.9747L10.1636 75.2809Z' fill='#AFD5D0' />
          <path d='M4.52222 97.284H4.5569L9.66629 97.1799V97.1568L10.3252 92.9839L10.2992 92.9752L5.14933 91.4639V91.4985L4.52222 97.284ZM9.61427 97.1221L4.58869 97.2233L5.19846 91.5419L10.2558 93.0273L9.61427 97.125V97.1221Z' fill='#A3D9D4' />
          <path d='M4.48752 99.5121L10.2529 98.9226V97.5123H10.2038L4.60312 97.7666V97.8071L4.48752 99.5092V99.5121ZM10.1633 98.8445L4.58289 99.4138L4.68693 97.8562L10.1633 97.6077V98.8445Z' fill='#A3D9D4' />
          <path d='M17.9605 106.87L20.7262 106.014L22.3677 77.1912L22.2752 77.1478L19.8361 81.9218L17.9605 106.87Z' fill='#A3D9D4' />
          <path d='M31.8436 100.232L35.2768 101.243L35.5918 98.9456L31.3638 97.6827L31.8436 100.232Z' fill='#BFE4E0' />
          <path d='M1.04295 112.086L-4.49415 114.126L-2.71106 91.6893L3.73926 90.4756L1.04295 112.086Z' fill='#A3D9D4' />
          <path d='M-4.29205 91.2356C-3.36439 90.912 -2.57832 90.5189 -2.57832 90.5189L3.79108 89.0249L5.58861 89.6173L6.09724 86.8893L12.7036 43.4178L-3.49443 50.0211L-4.29494 91.2327L-4.29205 91.2356Z' fill='#A3D9D4' />
          <path d='M20.8275 75.8531L22.8823 76.2172L23.8706 69.3655H21.5095L20.8275 75.8531Z' fill='#CCE9E6' />
          <path d='M33.7714 104.772L34.8436 81.8611H35.3146V83.3002H37.0688V83.1846H39.4097C39.4097 83.6932 38.9993 84.1036 38.4907 84.1036L37.0688 83.8551V83.5314H35.3146V104.774H33.7714V104.772Z' fill='#6E9DB1' />
          <path d='M40.7219 103.561L41.6495 86.5137H42.005V87.6002H43.3315V87.5135H45.1001C45.1001 87.8979 44.788 88.2071 44.4065 88.2071L43.3315 88.0193V87.7736H42.005V103.584L40.7219 103.561Z' fill='#A3D9D4' />
          <path d='M26.6245 110.803L29.8728 110.378L31.7368 55.8499L30.6589 57.7802L26.6245 110.803Z' fill='#87BCB6' />
          <path d='M34.8185 64.8035L26.4316 66.5448L26.4187 66.4826L34.8056 64.7412L34.8185 64.8035Z' fill='#87BCB6' />
          <path d='M34.9796 64.4725L26.5928 66.2139L26.5323 65.9224L34.9191 64.1811L34.9796 64.4725Z' fill='#87BCB6' />
          <path d='M35.147 63.5829L26.4656 65.2301L26.5899 65.8225L34.9765 64.0828L35.147 63.5829Z' fill='#87BCB6' />
          <path d='M27.168 61.3202L34.2512 60.9301L34.2686 61.4155L27.3963 61.6756L27.168 61.3202Z' fill='#87BCB6' />
          <path d='M27.6187 60.8L34.7048 60.4099L34.7222 60.8954L27.8499 61.1555L27.6187 60.8Z' fill='#87BCB6' />
          <path d='M32.2917 64.0886L33.7222 63.791L33.6152 63.2737L32.2223 63.7563L32.2917 64.0886Z' fill='#87BCB6' />
          <path d='M26.7258 80.0404L32.1733 81.6414L32.4219 81.3668L26.853 79.5L26.7258 80.0404Z' fill='#87BCB6' />
          <path d='M27.0058 83.3268L32.6997 84.6528L32.5739 85.1932L26.8799 83.8672L27.0058 83.3268Z' fill='#87BCB6' />
          <path d='M8.64077 59.5805L11.8457 42.5393L5.82887 43.7761L5.85488 43.8946L11.6983 42.6925L8.52229 59.5603L8.64077 59.5805Z' fill='#FFF3C4' />
        </g>
        <g style='mix-blend-mode:luminosity' opacity='0.5' clip-path='url(#clip6_51_4390)'>
          <path d='M40.7226 142.259L80.0458 138.849C80.829 134.387 82.8086 123.486 82.8086 123.486L76.4941 121.38L77.9015 107.069L75.8034 106.228L70.8183 104.234L67.833 103.041L67.7781 103.151L65.3419 107.925L66.021 102.356L69.0959 77.1048L58.5188 69.2965L58.2095 69.4237L42.0115 76.0269L41.211 117.239L40.7255 142.261L40.7226 142.259Z' fill='#CCE9E6' />
          <path d='M58.1344 76.7407L67.5353 80.8644L67.8677 78.1942L58.8569 73.0648L58.1344 76.7407Z' fill='#BFE4E0' />
          <path d='M56.2677 91.8976L65.8825 94.3049L66.1772 91.9179L56.9873 88.2218L56.2677 91.8976Z' fill='#BFE4E0' />
          <path d='M41.2079 117.236C42.1356 116.912 42.9217 116.519 42.9217 116.519L49.2911 115.025L51.0886 115.617L51.5972 112.889L58.2036 69.4178L42.0056 76.0211L41.2051 117.233L41.2079 117.236Z' fill='#A3D9D4' />
          <path d='M66.3275 101.853L68.3823 102.217L69.3706 95.3655H67.0095L66.3275 101.853Z' fill='#CCE9E6' />
          <path d='M72.1245 136.803L75.3728 136.378L77.2368 81.8499L76.1589 83.7802L72.1245 136.803Z' fill='#87BCB6' />
          <path d='M80.3185 90.8035L71.9316 92.5448L71.9187 92.4826L80.3056 90.7412L80.3185 90.8035Z' fill='#87BCB6' />
          <path d='M80.4796 90.4725L72.0928 92.2139L72.0323 91.9224L80.4191 90.1811L80.4796 90.4725Z' fill='#87BCB6' />
          <path d='M80.647 89.5829L71.9656 91.2301L72.0899 91.8225L80.4765 90.0828L80.647 89.5829Z' fill='#87BCB6' />
          <path d='M72.668 87.3202L79.7512 86.9301L79.7686 87.4155L72.8963 87.6756L72.668 87.3202Z' fill='#87BCB6' />
          <path d='M73.1187 86.8L80.2048 86.4099L80.2222 86.8954L73.3499 87.1555L73.1187 86.8Z' fill='#87BCB6' />
          <path d='M77.7917 90.0886L79.2222 89.791L79.1152 89.2737L77.7223 89.7563L77.7917 90.0886Z' fill='#87BCB6' />
          <path d='M54.1408 85.5805L57.3457 68.5393L51.3289 69.7761L51.3549 69.8946L57.1983 68.6925L54.0223 85.5603L54.1408 85.5805Z' fill='#FFF3C4' />
        </g>
        <g style='mix-blend-mode:luminosity' opacity='0.5' clip-path='url(#clip7_51_4390)'>
          <path d='M74.5234 155.259L113.847 151.849C114.63 147.387 116.609 136.486 116.609 136.486L110.295 134.38L111.702 120.069L109.604 119.228L104.619 117.234L101.634 116.041L101.579 116.151L99.1427 120.925L99.8218 115.356L102.897 90.1048L92.3195 82.2965L92.0103 82.4237L75.8123 89.0269L75.0118 130.239L74.5262 155.261L74.5234 155.259Z' fill='#CCE9E6' />
          <path d='M91.9352 89.7407L101.336 93.8644L101.668 91.1942L92.6577 86.0648L91.9352 89.7407Z' fill='#BFE4E0' />
          <path d='M75.0087 130.236C75.9364 129.912 76.7225 129.519 76.7225 129.519L83.0919 128.025L84.8894 128.617L85.398 125.889L92.0044 82.4178L75.8064 89.0211L75.0058 130.233L75.0087 130.236Z' fill='#A3D9D4' />
          <path d='M105.925 149.803L109.173 149.378L111.037 94.8499L109.959 96.7802L105.925 149.803Z' fill='#87BCB6' />
          <path d='M87.9411 98.5805L91.146 81.5393L85.1292 82.7761L85.1552 82.8946L90.9986 81.6925L87.8226 98.5603L87.9411 98.5805Z' fill='#FFF3C4' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_51_4390'>
          <rect width='447.2' height='104' fill='white' transform='translate(17.6377)' />
        </clipPath>
        <clipPath id='clip1_51_4390'>
          <rect width='88.4' height='126.1' fill='white' transform='translate(259.438 23.4)' />
        </clipPath>
        <clipPath id='clip2_51_4390'>
          <rect width='88.4' height='126.1' fill='white' transform='translate(211.338 44.2001)' />
        </clipPath>
        <clipPath id='clip3_51_4390'>
          <rect width='88.4' height='126.1' fill='white' transform='translate(302.337 13.0001)' />
        </clipPath>
        <clipPath id='clip4_51_4390'>
          <rect width='447.2' height='104' fill='white' transform='matrix(-1 0 0 1 327.2 0)' />
        </clipPath>
        <clipPath id='clip5_51_4390'>
          <rect width='88.4' height='126.1' fill='white' transform='matrix(-1 0 0 1 45.1001 5.20007)' />
        </clipPath>
        <clipPath id='clip6_51_4390'>
          <rect width='88.4' height='126.1' fill='white' transform='matrix(-1 0 0 1 90.6001 31.2001)' />
        </clipPath>
        <clipPath id='clip7_51_4390'>
          <rect width='88.4' height='126.1' fill='white' transform='matrix(-1 0 0 1 124.4 44.2001)' />
        </clipPath>
      </defs>
    </svg>

  );
};
export default FooterSunsetMobile;
