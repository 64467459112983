import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import useStyles from "./styles";
import BankFields from "./bank-fields";
import Button from "../button";
import { toIndianCurrencyWithZero } from "src/utils/conversions";

const PaymentMethod = ({
  totalRefundAmount,
  saveRefundPreferences
}: {
  totalRefundAmount: number;
  saveRefundPreferences: (payload: any) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState("giveBankAccount");
  const [bankDetails, setBankDetails] = useState({
  });
  const [bankFormHasErrors, setBankFormHasErrors] = useState(false);
  const styles = useStyles();
  const [bankFormEmpty, setBankFormEmpty] = useState(true);

  useEffect(() => {
    const hasBankDetailsValues = Object.values(bankDetails).every(
      (i) => i != ''
    );
    if (selectedValue === 'giveBankAccount') {
      setBankFormEmpty(!hasBankDetailsValues);
    }
  }, [selectedValue, bankDetails]);

  const handleBankDetailsHasErrors = (bankDetailsErrors: any) => {
    const hasErorrs = Object.values(bankDetailsErrors).some((i) => i !== "");
    setBankFormHasErrors(hasErorrs);
  };

  const handleSelection = (value: any) => {
    setSelectedValue(value);
  };

  const getFormValues = (bankDetails: any) => {
    setBankDetails(bankDetails);
  };

  const isDisabled =
    selectedValue === 'giveBankAccount'
      ? bankFormHasErrors || bankFormEmpty
      : false;
  return (
    <div>
      <div className={styles.headerTitle}>Choose payment method</div>
      <div className={styles.optionCardContainer}>
{/*         <div
          className={`${styles.optionCard} ${
            selectedValue === "returnToSource" ? styles.selectedCard : ""
          }`}
          onClick={() => handleSelection("returnToSource")}
        >
          <div className={styles.textContainer}>
            <span className={styles.title}>Return to source</span>
            <span className={styles.description}>
              Get refund in next 7 days
            </span>
          </div>
          <input
            type='radio'
            checked={selectedValue === "returnToSource"}
            className={styles.radio}
            readOnly
          />
        </div> */}

        <div
          className={`${styles.optionCard} ${
            selectedValue === "giveBankAccount" ? styles.selectedCard : ""
          }`}
          onClick={() => handleSelection("giveBankAccount")}
        >
          <div className={styles.textContainer}>
            <span className={styles.title}>Give Bank account</span>
            <span className={styles.description}>
              Get refund in next 15 days
            </span>
          </div>
          <input
            type='radio'
            checked={selectedValue === "giveBankAccount"}
            className={styles.radio}
            readOnly
          />
        </div>
      </div>
      {selectedValue === "giveBankAccount" && (
        <BankFields
          hasErrors={handleBankDetailsHasErrors}
          getFormValues={getFormValues}
        />
      )}
      <div style={{
        width: "100%", marginTop: 20
      }}
      >
        <Button
          disabled={isDisabled}
          title={`Send Refund Request of ${toIndianCurrencyWithZero(
            totalRefundAmount
          )}`}
          onClick={() => {
            if (isDisabled) return;
            saveRefundPreferences({
              toSource: selectedValue !== "giveBankAccount",
              bankDetails:
                selectedValue === "giveBankAccount" ? bankDetails : null
            });
          }}
        />
      </div>
    </div>
  );
};

export default PaymentMethod;
