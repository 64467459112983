import styles from './button.css';

import {
  type JSX, h, VNode
} from 'preact';

export interface ButtonProps {
  label?: string
  name?: string
  onClick?: (event: Event) => void
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: string | JSX.Element | JSX.Element[] | VNode<any> | VNode<any>[]
  isLink?: boolean
  disabled?: boolean
}

const Button = ({
  label,
  name,
  onClick,
  className,
  children,
  isLink,
  disabled
}: ButtonProps) => {
  let btnClass = isLink ? styles.link : styles.button;
  btnClass += disabled ? ` ${styles.disabled}` : '';

  const _onClick = isLink
    ? (e: Event) => {
      e.preventDefault();
      onClick && onClick(e);
    }
    : onClick;

  return (
    <button className={`pure-button ${btnClass} ${className}`}
      onClick={_onClick}
      name={name}
    >{label || children}</button>
  );
};

export default Button;
