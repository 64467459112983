import { h } from 'preact';
import { useEffect, useState } from "preact/hooks";
import useStyles from "./styles";

const BankForm = ({
  hasErrors,
  getFormValues
}: {
  hasErrors: any;
  getFormValues: (payload: any) => void;
}) => {
  const styles = useStyles();
  const [formValues, setFormValues] = useState({
    beneficiaryName: "",
    accountNumber: "",
    ifscCode: ""
  });

  const [formErrors, setFormErrors] = useState({
    beneficiaryName: "",
    accountNumber: "",
    ifscCode: ""
  });

  const validateBeneficiaryName = (name: string) => {
    const regex = /^[A-Za-z\s]*$/;
    if (!name) {
      return "Beneficiary name is required";
    } else if (!regex.test(name)) {
      return "Please enter a valid name";
    }
    return "";
  };

  const validateAccountNumber = (number: any) => {
    const regex = /^\d{9,18}$/;
    if (!number) {
      return "Bank account number is required";
    }
    if (!regex.test(number)) {
      return "Please enter a valid account number";
    }
    return "";
  };

  const isValidIFSC = (str: string) => {
    if (!str) return true;
    const regExp = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    return regExp.test(str);
  };

  const validateIFSCCode = (ifsc: string) => {
    if (!ifsc) {
      return "IFSC Code is required";
    }
    if (!isValidIFSC(ifsc)) {
      return "Invalid IFSC code";
    }
    return "";
  };

  const handleChange = (e: any) => {
    const {
      name, value
    } = e.target;

    if (name === "beneficiaryName") {
      const lettersRegex = /^[A-Za-z\s]*$/;
      if (!lettersRegex.test(value)) {
        return; // Prevent input if invalid
      }
    }

    if (name === "accountNumber") {
      const numbersRegex = /^\d*$/;
      if (!numbersRegex.test(value)) {
        return; // Prevent input if invalid
      }
    }

    setFormValues({
      ...formValues,
      [name]: value
    });

    // Inline validation
    let errorMessage = "";
    if (name === "beneficiaryName") {
      errorMessage = validateBeneficiaryName(value);
    } else if (name === "accountNumber") {
      errorMessage = validateAccountNumber(value);
    } else if (name === "ifscCode") {
      errorMessage = validateIFSCCode(value);
    }

    setFormErrors({
      ...formErrors,
      [name]: errorMessage
    });
  };

  useEffect(() => {
    hasErrors(formErrors);
  }, [formErrors]);

  useEffect(() => {
    getFormValues(formValues);
  }, [formValues]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors: any = {
    };

    // Validate all fields
    errors.beneficiaryName = validateBeneficiaryName(
      formValues.beneficiaryName
    );
    errors.accountNumber = validateAccountNumber(formValues.accountNumber);
    errors.ifscCode = validateIFSCCode(formValues.ifscCode);

    setFormErrors(errors);

    if (Object.keys(errors).every((key) => !errors[key])) {
      console.log("Form Submitted", formValues);
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <div className={styles.inputField}>
        <label className={styles.label}>
          Beneficiary name<span className={styles.required}>*</span>
        </label>
        <input
          name='beneficiaryName'
          type='text'
          placeholder='Enter Bank name'
          value={formValues.beneficiaryName}
          onChange={handleChange}
          className={`${styles.input} ${
            formErrors.beneficiaryName ? styles.inputError : ""
          }`}
        />
        {formErrors.beneficiaryName && (
          <div className={styles.errorMessage}>
            {formErrors.beneficiaryName}
          </div>
        )}
      </div>

      <div className={styles.inputField}>
        <label className={styles.label}>
          Bank Account number<span className={styles.required}>*</span>
        </label>
        <input
          name='accountNumber'
          type='text'
          placeholder='Enter bank account number'
          value={formValues.accountNumber}
          onChange={handleChange}
          className={`${styles.input} ${
            formErrors.accountNumber ? styles.inputError : ""
          }`}
        />
        {formErrors.accountNumber && (
          <div className={styles.errorMessage}>{formErrors.accountNumber}</div>
        )}
      </div>

      <div className={styles.inputField}>
        <label className={styles.label}>
          Bank IFSC Code<span className={styles.required}>*</span>
        </label>
        <input
          name='ifscCode'
          type='text'
          placeholder='Enter IFSC Code'
          value={formValues.ifscCode}
          onChange={handleChange}
          className={`${styles.input} ${
            formErrors.ifscCode ? styles.inputError : ""
          }`}
        />
        {formErrors.ifscCode && (
          <div className={styles.errorMessage}>{formErrors.ifscCode}</div>
        )}
      </div>
    </form>
  );
};

export default BankForm;
