import styles from './styles.css';

import { h } from 'preact';
import Button from 'src/components/common/Button';
import { Logo } from 'src/assets/icons/svg/Logo';
import { Link } from 'preact-router';
import { clickUserLogout } from 'src/analytics/eventTrackers';

// import Logo from 'src/assets/icons/logo.svg';

export const Header = () => {
  return (
    <div className={styles.header}>
      <div>
        <Link href='/'>
          <Logo />
        </Link>
      </div>
      <Button label='Logout'
        className={styles.logout}
        onClick={() => {
          clickUserLogout();
          window.location.href = '/logout';
        }}
      />
    </div>
  );
};
