import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  optionCardContainer: {
    display: "flex",
    gap: "16px",
    marginTop: "10px"
  },
  headerTitle: {
    textAlign: "left",
    fontSize: "12px",
    padding: "12px 0 0 0"
  },
  optionCard: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    border: "1px solid #DDDDDD",
    borderRadius: "8px",
    padding: "16px",
    cursor: "pointer",
    transition: "border-color 0.3s ease",
    "&:hover": {
      borderColor: "#1976d2" // Blue border on hover
    }
  },
  selectedCard: {
    borderColor: "#1976d2", // Selected state blue border
    backgroundColor: "#F0F4FF" // Light blue background for selected
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 100
  },
  title: {
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "8px",
    textAlign: "left"
  },
  description: {
    color: "#6E6E6E",
    fontSize: "12px"
  },
  radio: {
    marginLeft: "auto",
    accentColor: '#1976d2'
  }
}));

export default useStyles;
