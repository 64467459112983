import {
  VNode, createContext, h
} from 'preact';
import { useContext, useEffect } from 'preact/compat';
import {
  initializeAnalytics,
  resetAnalytics,
  sendEvent,
  setUserInAnalytics,
  unsetUserInAnalytics
} from 'src/analytics';
import { EVENTS/* , FirebaseEvents */ } from 'src/analytics/constants';
import { AuthContext } from './AuthProvider';

export interface AnalyticsContextType {
  sendEvent: (
    event: EVENTS,
    data: {
      [key: string]: string | number | boolean | object | null | undefined
    } ) => void
}

export const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

const AnalyticsProvider = ({
  children
}: { children: Array<VNode | string | null> }) => {
  const authContext = useContext(AuthContext);
  useEffect(() => {
    initializeAnalytics();
    return () => {
      resetAnalytics();
    };
  }, []);

  useEffect(() => {
    if (authContext?.user) {
      authContext?.user && setUserInAnalytics(authContext?.user);
    }

    return () => {
      unsetUserInAnalytics();
    };
  }
  , [authContext?.user]);

  return (
    <AnalyticsContext.Provider value={{
      sendEvent
    }}
    >{children}</AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
