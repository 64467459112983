// import { sendEvent } from 'src/integrations/analytics'
// import { SubscriptionHelper } from './subscription-helpers'
import { BenefitEntity } from 'src/models';
import { EVENTS } from './constants';
import { sendEvent } from './index';
import { getWebBenefit } from 'src/routes/Subscribe/utils';

const UTM_PREFIX = 'utm_';

function getUTMParams() {
  const queryObj: { [key: string]: string | null } = {
  };
  if (window.location.search) {
    const queryParams = new URLSearchParams(window.location.search);
    for (const key of queryParams.keys()) {
      if (key && key.startsWith(UTM_PREFIX)) {
        queryObj[key] = queryParams.get(key);
      }
    }
  }

  return queryObj;
}

const triggerEvent = (eventType: EVENTS, data: object = {
}) => {
  sendEvent(eventType, {
    Device: 'mweb', // SubscriptionHelper.isInvokedByAndroid() ? 'Android' : 'Web',
    feature: 'Subscription',
    ...data
  });
};

const checkoutPlanOptionSelection = (planId: string) => {
  triggerEvent(EVENTS.CheckoutPlanOptionSelection, {
    planId
  });
};

const checkoutDiscountApplyClick = (discountId: string, isValid: boolean) => {
  triggerEvent(EVENTS.CheckoutDiscountApplyClick, {
    discountId,
    validityFlag: isValid ? 'valid' : 'invalid'
  });
};

const checkoutGstInvoiceClick = (invoiceRequired: string) => {
  triggerEvent(EVENTS.CheckoutGstInvoiceClick, {
    invoiceRequired
  });
};

const checkoutContinueClick = (
  partnerCodePreApplied: boolean,
  referralCode: string,
  planId: string,
  isGstInvoiceRequired: boolean,
  hasTrialEnded: boolean,
  isUpgradeFromAndroidPro: boolean,
  validDiscountCoupon?: string
) => {
  triggerEvent(EVENTS.CheckoutContinueClick, {
    partnerCodePreApplied,
    partnerCode: referralCode,
    planId,
    validDiscountCoupon,
    gstInvoiceRequiredFlag: isGstInvoiceRequired ? 'Yes' : 'No',
    trialEndedFlag: hasTrialEnded ? 'Yes' : 'No',
    isUpgradeFromAndroidPro
  });
};

const checkoutSaveAndMakePaymentClick = (properties: object) => {
  triggerEvent(EVENTS.CheckoutSaveAndMakePaymentClick, properties);
};

const subscriptionSuccessConfirmation = (
  partnerCode: string,
  orderId: string,
  planId: string,
  isDiscountApplied: boolean,
  planPrice: number,
  amountPaid: number,
  discountAmount: number,
  discountCode?: string,
  isUpgradeFromAndroidPro?: boolean
) => {
  triggerEvent(EVENTS.SubscriptionSuccessConfirmation, {
    partnerCode,
    orderId,
    planId,
    planPrice,
    amountPaid,
    discountAmount,
    discountFlag: isDiscountApplied ? 'Y' : 'N',
    discountCode,
    isUpgradeFromAndroidPro
  });
};

const partnerCodeApplied = (properties: object) => {
  triggerEvent(EVENTS.PartnerCodeApplied, {
    properties
  });
};

/*
"trialDays: no of days remaining in trial,
inTrial: true/false (if the user is currently in trial period or not),
subscribed: true/false (true if the user has subscribed),
subscription_ended: true/false,
kb_device = mweb"
*/
interface TrialData {
  trialDays: number;
  inTrial: boolean;
  subscribed: boolean;
  subscription_ended: boolean;
  kb_device: string;
}

const subscriptionPageLoaded = (eventType: EVENTS) => {
  const urlParams = getUTMParams();
  const payload = {
    eventType,
    message: 'Page loaded',
    ...urlParams

  };
  triggerEvent(eventType, payload);
};

const loggedInPageView = async (benefits: BenefitEntity[]) => {
  let benefitsData: TrialData | null = null;
  if (benefits) {
    const activeBenefit = getWebBenefit(benefits);
    if (activeBenefit) {
      const data = {
        trialDays: activeBenefit.validity.remaining_period,
        inTrial: activeBenefit.validity.remaining_period > 0,
        subscribed: activeBenefit.meta.has_purchased_once,
        subscription_ended: activeBenefit.meta.has_active_purchase,
        kb_device: 'mweb'
      };
      benefitsData = data;
    }
  }
  const payload = {
    eventType: EVENTS.MWebLoginPageView,
    ...benefitsData
  };
  await sendEvent(EVENTS.MWebLoginPageView, payload);
};

const clickUserLogout = async () => {
  await sendEvent(EVENTS.UserLogoutClick);
};

const onUserLogoutSuccess = async () => {
  await sendEvent(EVENTS.UserLogoutSuccess);
};

const callUsClick = () => {
  triggerEvent(EVENTS.MWebCallUsClick);
};

export {
  checkoutPlanOptionSelection,
  checkoutDiscountApplyClick,
  checkoutGstInvoiceClick,
  checkoutContinueClick,
  checkoutSaveAndMakePaymentClick,
  subscriptionSuccessConfirmation,
  partnerCodeApplied,
  subscriptionPageLoaded,
  clickUserLogout,
  onUserLogoutSuccess,
  callUsClick,
  loggedInPageView
};
