import { BenefitEntity } from 'src/models';
import {
  BillingDetail,
  PurchaseItem,
  OrderEntity,
  PurchasedSubscriptionDetail
} from 'src/models/orders';
import {
  getPriceToDisplay,
  combineBillingPeriodAndUnit,
  isAfterNow
} from 'src/utils/conversions';
import {
  MOBILE_BENEFIT_TYPE,
  PLAN_STATUS,
  WEB_BENEFIT_TYPE
} from './constants';
import { aesUtil } from 'src/utils/crypto';

export interface UserCredentials {
  token: string
  userId: string
  phone: string
  s: string
  lang: string,
  deviceId: string, // run this with android team to make sure this is provided by the bridge
}

const getBillingDetails = (orderDetails: OrderEntity[]): BillingDetail[] => {
  const billingDetails: BillingDetail[] = orderDetails
    .map((orderDetail) => {
      const purchaseItems = orderDetail.purchase_items;
      return purchaseItems.map((purchasedItem: PurchaseItem) => {
        if (purchasedItem.purchased_subscription_detail == null) {
          return undefined;
        }
        if (!purchasedItem.inventory_item.benefits?.[WEB_BENEFIT_TYPE] &&
          !purchasedItem.inventory_item.benefits?.[MOBILE_BENEFIT_TYPE]) {
          return undefined;
        }
        if (
          purchasedItem.purchased_subscription_detail.billing_method ===
          'free_trial'
        ) {
          return undefined;
        }

        if (purchasedItem.plan_info == null) {
          const error = new Error(
            `Invalid data received from server: 
            Plan info must be present if purchase item is a web subscription`
          );
          /* captureException(error, {
            tags: getEntityAction(
              AppEntitiesMap.SUBSCRIPTION,
              undefined,
              'ORDER_HISTORY'
            ),
          }) */
          console.log(error);
          return undefined;
        }

        const billingDetails: BillingDetail = {
          orderId: orderDetail.id,
          description: `${purchasedItem.plan_info.title} / 
              ${combineBillingPeriodAndUnit(
            purchasedItem.purchased_subscription_detail.billing_period,
            purchasedItem.purchased_subscription_detail.billing_period_unit
          )}`,
          status: isPlanActive(purchasedItem.purchased_subscription_detail)
            ? PLAN_STATUS.ACTIVE
            : PLAN_STATUS.EXPIRED,
          pricePaid: `${getPriceToDisplay({
            price: purchasedItem.price_paid || 0,
            isTaxIncluded: true // purchasedItem.inventory_item.does_price_includes_tax
          })}`,
          orderCreatedAt: purchasedItem.created_at,
          expiryDate: purchasedItem?.purchased_subscription_detail?.validity_end,
          benefit: purchasedItem?.inventory_item.benefits
        };
        return billingDetails;
      });
    })
    .flat()
    .filter((x) => x !== undefined) as BillingDetail[];

  billingDetails.sort((detail1: BillingDetail, detail2: BillingDetail) => {
    if (detail1.status !== detail2.status) {
      // ACTIVE should come before EXPIRED
      if (detail1.status === PLAN_STATUS.ACTIVE) {
        return -1;
      }
      return 1;
    }
    // sort by created at descending
    return detail1.orderCreatedAt < detail2.orderCreatedAt ? 1 : -1;
  });

  return billingDetails;
};

const isPlanActive = (
  purchasedSubscriptionDetail: PurchasedSubscriptionDetail
) => {
  const validityEnd = purchasedSubscriptionDetail.validity_end;
  return !isAfterNow(validityEnd);
};

const isSubscribed = (benefits: BenefitEntity[]) => {
  return !!benefits.find(
    (benefit) =>
      benefit.type === WEB_BENEFIT_TYPE &&
            benefit.validity.remaining_period > 0
  );
};

const getActiveFreeTrial = (benefits: BenefitEntity[]) => {
  return benefits.find(
    (benefit) => benefit.type === WEB_BENEFIT_TYPE && benefit.meta.is_trial
  );
};

const hasActivePurchasedPlan = (benefits: BenefitEntity[]) => {
  return !!benefits.find(
    (benefit) => benefit.meta.has_active_purchase
  );
};

const isUserOnFreeTrial = (benefits: BenefitEntity[]) => {
  return (
    isSubscribed(benefits) &&
        !!getActiveFreeTrial(benefits) &&
        !hasActivePurchasedPlan(benefits)
  );
};

const getWebBenefit = (benefits: BenefitEntity[]) => {
  let webBenefits = benefits.filter(
    (benefit) => benefit.type === WEB_BENEFIT_TYPE && benefit.meta.has_active_purchase
  );
  if (!webBenefits.length) {
    webBenefits = benefits.filter(
      (benefit) => benefit.meta.has_active_purchase
    );
  }
  if (webBenefits.length === 0) {
    return undefined;
  } else if (webBenefits.length === 1) {
    return webBenefits[0];
  }
  const err = new Error(
    `Unexpected state, got 2 objects with benefit type ${WEB_BENEFIT_TYPE}, expected only 1`
  );
    /* captureException(err, {
            tags: getEntityAction(
                AppEntitiesMap.SUBSCRIPTION,
                undefined,
                'getInvoicePartyIfPresent'
            ),
        }) */
  throw err;
};

const isInvokedByAndroid = () => {
  const host = window?.DesktopSubscriptionWebViewHandler?.getHost();
  return host === 'android';
};

const getUserCredentialsFromAndroidBridge = () => {
  const strCredentials: string =
    window?.DesktopSubscriptionWebViewHandler?.getCredentials();
  let credentials: UserCredentials | undefined;
  try {
    credentials = JSON.parse(JSON.parse(strCredentials));
  } catch (e) {
    const message = 'Unable to parse credentials received from android bridge';
    console.error(message, e);
    throw new Error(message);
  }
  if (
    !credentials ||
    !credentials.token ||
    !credentials.phone ||
    !credentials.userId
  ) {
    const exception = new Error(
      'Invocation from android but unable to fetch credentials correctly'
    );
    /* captureException(exception, {
      tags: getEntityAction(
        AppEntitiesMap.SUBSCRIPTION,
        undefined,
        'GET_USER_CREDENTIALS_FROM_ANDROID_BRIDGE'
      )
    }); */
    throw exception;
  }

  return decryptCredentials(credentials);
};

const decryptCredentials = (credentials: UserCredentials) => {
  const decryptionKey =
    process.env.ENV_ANDROID_CREDENTIALS_DECRYPTION_KEY || '';
  let token = credentials.token.replace(/\r?\n/g, '');
  const userId = aesUtil.decrypt(decryptionKey, credentials.userId);
  token = aesUtil.decrypt(decryptionKey, token);
  const phone = aesUtil.decrypt(decryptionKey, credentials.phone);
  // console.log('Result after decryption', { userId, token, phone })
  return {
    ...credentials,
    userId,
    token,
    phone
  };
};

export {
  getBillingDetails,
  isUserOnFreeTrial,
  getWebBenefit,
  isSubscribed,
  hasActivePurchasedPlan,
  getActiveFreeTrial,
  isInvokedByAndroid,
  getUserCredentialsFromAndroidBridge
};
