import { h } from 'preact';
import { makeStyles } from "@mui/styles";
import FeatureInfo from './feature-info';

const useStyles = makeStyles(() => ({
  container: {
    padding: "32px",
    maxWidth: "800px",
    margin: "0 auto",
    fontWeight: 500
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "16px"
  },
  subheading: {
    fontSize: "20px",
    marginTop: "24px",
    textAlign: 'center'
  },
  paragraph: {
    fontSize: "14px",
    lineHeight: "1.6",
    marginBottom: "16px",
    fontWeight: 500,
    textAlign: 'center'
  },
  label: {
    fontWeight: 700
  }
}));

const data = [
  {
    feature: 'Party Transactions - Customer (You Gave/ You Got)',
    status: 'Will be continued on App & Desktop',
    color: 'green'
  },
  {
    feature: 'Party Transactions - Supplier (You Gave/ You Got)',
    status: 'Will be continued on App & Desktop',
    color: 'green'
  },
  {
    feature: 'Expenses', status: 'Will be continued on App & Desktop', color: 'green'
  },
  {
    feature: 'Cashbook', status: 'Will be continued on App & Desktop', color: 'green'
  },
  {
    feature: 'Reports - Parties', status: 'Will be continued on App & Desktop', color: 'green'
  },
  {
    feature: 'Staff', status: 'Will be continued on App & Desktop', color: 'green'
  },
  {
    feature: 'Items', status: 'Will be Discontinued on Desktop and will be continued on App', color: 'red'
  },
  {
    feature: 'Sales', status: 'Will be Discontinued on Desktop and will be continued on App', color: 'red'
  },
  {
    feature: 'Purchase', status: 'Will be Discontinued on Desktop and will be continued on App', color: 'red'
  },
  {
    feature: 'Delivery Challan', status: 'Will be Discontinued on Desktop', color: 'red'
  },
  {
    feature: 'Performa Invoice', status: 'Will be Discontinued on Desktop', color: 'red'
  },
  {
    feature: 'Quotation/Estimate',
    status: 'Will be Discontinued on Desktop',
    color: 'red'
  },
  {
    feature: 'Reports - Bills', status: 'Will be Discontinued on Desktop and will be continued on App', color: 'red'
  },
  {
    feature: 'Reports - GST', status: 'Will be Discontinued on Desktop and will be continued on App', color: 'red'
  },
  {
    feature: 'Reports - Day Wise',
    status: 'Will be Discontinued on Desktop and will be continued on App',
    color: 'red'
  }
];

const columns = [
  {
    key: "feature", label: "Feature"
  },
  {
    key: "status", label: "Post 31st Oct, 2024"
  }
];

const ShutdownInfo = () => {
  const styles = useStyles();

  return (
    <div id='shutdown-info' className={styles.container}>
      <h3 className={styles.subheading}>
        Why are we shutting down Bills and Inventory?
      </h3>
      <p className={styles.paragraph}>
        At Khatabook, we prioritize solving challenges for the Indian SME community.
        Our customers expressed a need for a desktop-first billing and inventory management solution,
        and we delivered.Since November 2022, we’ve been committed to providing a robust Desktop App for Ledger,
        Billing, and Inventory management. Over the years through data, we’ve learned that the majority of India’s
        business owners prefer using mobile for Billing & Accounting Solutions. To continue innovating and solving for
        the mass business owners in India, we’ve decided to focus our complete energy on enhancing Khatabook Mobile App and
        make it a comprehensive business & accounting solution. As a result, we will be discontinuing the Billing &
        Inventory Services on our Desktop App from 31st October 2024.
      </p>
      <p className={styles.paragraph}> However, staying true to our commitment of empowering
        business owners across India, we are making Billing & Inventory Management Services completely free on the Mobile App
        starting today. This ensures that you have seamless access to powerful tools that help you manage your business
        efficiently. Moreover, we are making the Khatabook Desktop App free as well, with the Khatabook Ledger available at no
        cost.
      </p>

      <h3 className={styles.subheading}>Changes that are being made</h3>
      <FeatureInfo data={data} columns={columns} />
    </div>
  );
};

export default ShutdownInfo;
