import { isInvokedByAndroid } from "src/routes/Subscribe/utils";

export const isLocalHost = () => {
  return window.location.hostname === 'localhost';
};

export const nextTick = (fn : () => void) => {
  setTimeout(fn, 0);
};

let redirectTimeout: ReturnType<typeof setTimeout> | null = null;

export const redirectToSource = (inTime = 10000) => {
  const isAndroid = isInvokedByAndroid();
  if (isAndroid) {
    window?.DesktopSubscriptionWebViewHandler?.onDismissWebView();
  } else {
    if (redirectTimeout) clearTimeout(redirectTimeout);

    redirectTimeout = setTimeout(() => {
      window.location.href = '/';
    }, inTime);
  }
};
