import {
  JSX, createContext, h
} from 'preact';
import { useEffect, useState } from 'preact/compat';
import {
  KBUser, handleLogin, removeAllCookies
} from './utils';

export interface AuthContextType {
  user: KBUser | null
  logout: () => void
}

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider = ({
  children
}: { children: JSX.Element | JSX.Element[] }) => {
  const [user, setUser] = useState<KBUser | null>(null);

  const logout = () => {
    removeAllCookies();
    setUser(null);
  };

  useEffect(() => {
    setUser(handleLogin());
  }, []);

  return (
    <AuthContext.Provider value={{
      user,
      logout
    }}
    >{user ? children : null}</AuthContext.Provider>
  );
};

export default AuthProvider;
