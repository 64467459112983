/* eslint-disable camelcase */
import { getUserCredentialsFromAndroidBridge, isInvokedByAndroid } from 'src/routes/Subscribe/utils';
import { defaultHeaders, setFetchHeaders } from '../network/api';
import { isLocalHost } from '../utils';

export interface KBUser {
  token: string | null
  user_id: string | null
  device_id: string | null
  phone: string | null
  is_new_user: boolean
  language: string
}

const handleLocalHostLogin = (): KBUser | null => {
  let token: string | null = null;
  let user_id: string | null = null;
  let device_id: string | null = null;
  let phone: string | null = null;
  let is_new_user = false;
  let language = '-1';

  const urlParams = new URLSearchParams(window.location.search);
  token = urlParams.get('token');
  user_id = urlParams.get('user_id');
  device_id = urlParams.get('device_id');
  phone = urlParams.get('phone');
  is_new_user = urlParams.get('is_new_user') ? urlParams.get('is_new_user') === 'true' : false;
  language = urlParams.get('language') || language;

  if (!token || !user_id || !device_id || !phone) {
    console.log('User information not found');
    console.log(`user_id: ${user_id}, token: ${token}, device_id: ${device_id}, phone: ${phone}`);
    window.location.href = 'http://localhost:3000';
  }

  if (token) {
    setFetchHeaders({
      ...defaultHeaders,
      'x-khatabook-token': token
    });
  }

  return {
    token,
    user_id,
    device_id,
    phone,
    is_new_user,
    language
  };
};

export const getCookieValue = (
  name: string
) => {
  const cookieName = process.env.ENV_NODE === 'production' ? name : `dev-${name}`;
  /**
   * @description This function is used to get the value of a cookie from above map if user
   * is on demo account url or else it will get the value of the cookie from the browser
   * @param name is a string that represents the name of the cookie
   * @param isMainAccountLoggedIn is a boolean that represents if the user is logged in to the main
   * account or not will be used to show demo account's banner details
   */
  return window.document.cookie
    .match(
      `(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`
    )
    ?.pop() || '';
};

export const removeAllCookies = () => {
  /**
  We split the cookies by ; to create an array.
  We iterate through the array of cookies to replace its content
  with an expiry date of today (new Date().toUTCString())
  Having today’s time and date essentially voids the cookie
  and it is removed from the browser.
  */
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(
        /=.*/,
        `=;expires=${
          new Date().toUTCString()
        };path=/` +
        `;domain=.khatabook.com`
      );
  });
};

export const userInfoKey = 'userInfoFromBridge';

/**
 * fetches user infromation stored in the local storage.
 * This is to be used ONLY in case of android app driven login.
 * There is a bug there wherein token information does not come correctly if:
 *  1. The webview refreshes
 *  2. The user gets redirected back from the payment service
 */
const getBridgeUserInfoFromLS = (): KBUser | null | undefined => {
  try {
    const userInfo = window.localStorage.getItem(userInfoKey);

    if (!userInfo) return null;

    return JSON.parse(userInfo);
  } catch (e) {
    console.error(e);
  }
};

const setBridgeUserInfoToLS = (userInfo: KBUser) => {
  window.localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
};

export const setUserFromBridge = (): KBUser | null => {
  try {
    const userInfo = getUserCredentialsFromAndroidBridge();
    const {
      token,
      userId,
      phone,
      deviceId,
      lang
    } = userInfo;

    if (!token || !userId || !phone) {
      throw new Error('User information incomplete');
    }

    setFetchHeaders({
      ...defaultHeaders,
      'x-khatabook-token': token
    });

    setBridgeUserInfoToLS({
      token,
      is_new_user: false,
      user_id: userId,
      device_id: deviceId,
      language: lang || '-1',
      phone
    });

    return {
      token,
      is_new_user: false,
      user_id: userId,
      device_id: deviceId,
      language: lang || '-1',
      phone
    };
  } catch (e) {
    console.log(e);
    console.log('User information could not be extracted from android bridge');
    return null;
  }
};

export const handleCookieBasedLogin = (): KBUser | null => {
  const kbToken = getCookieValue('kb-token');
  const kbUserId = getCookieValue('kb-user-id');
  const kbDeviceId = getCookieValue('kb-device-id');
  const kbIsNewUserStr = getCookieValue('kb-is-new-user') ? getCookieValue('kb-is-new-user') === 'true' : false;
  const kbLanguage = getCookieValue('kb-language');
  const kbPhone = getCookieValue('kb-phone');
  // const kbCountryCode = getCookieValue('kb-country-code');

  if (!kbToken || !kbUserId || !kbDeviceId || !kbPhone) {
    console.log(
      `user information is not found in cookies. User not logged in. Login should fix this.`
    );
    window.location.href = process.env.ENV_REDIRECT_ENDPOINT || 'https://dev.khatabook.com';
  }

  if (kbToken) {
    setFetchHeaders({
      ...defaultHeaders,
      'x-khatabook-token': kbToken
    });
  }

  return {
    token: kbToken,
    is_new_user: kbIsNewUserStr,
    user_id: kbUserId,
    device_id: kbDeviceId,
    language: kbLanguage || '-1',
    phone: kbPhone
  };
};

export const setCookie = (name: string, value: string, options: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
} = {
}) => {
  options = {
    path: '/',
    secure: true,
    domain: '.khatabook.com',
    sameSite: 'None',
    ...options
  };

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export function handleLogin (): KBUser | null {
  const isLocal = isLocalHost();

  // localhost based login *** FOR DEV WORK ONLY ***
  if (isLocal) {
    return handleLocalHostLogin();
  }

  // handle login for Android bridge use-case
  if (isInvokedByAndroid()) {
    setUserFromBridge();
    const userInfo = getBridgeUserInfoFromLS() as KBUser;
    if (userInfo?.token) {
      setFetchHeaders({
        ...defaultHeaders,
        'x-khatabook-token': userInfo.token
      });
    }
    return userInfo;
  }

  // Cookie based login for users on typical browsers
  return handleCookieBasedLogin();
}
