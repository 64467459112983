import { h } from 'preact';
import { makeStyles } from "@mui/styles";

type Column = {
  key: string;
  label: string;
};

type TableProps = {
  data: any[];
  columns: Column[];
};

const useStyles = makeStyles((theme: any) => ({
  tableContainer: {
    border: "1px solid #E4E7EC",
    borderRadius: 8,
    width: "100%",
    overflowX: "auto", // For small screens
    marginBottom: 16
  },
  table: {
    width: "100%",
    borderCollapse: "collapse"
  },
  th: {
    backgroundColor: "#f9f9f9",
    textAlign: "left",
    fontSize: 14,
    fontWeight: 700,
    padding: "12px 16px",
    width: "100%",
    boxSizing: "border-box"
  },
  td: {
    fontSize: 12,
    padding: "12px 16px",
    textAlign: "left"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff"
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#f9f9f9"
    }
  }
}));

const ResponsiveTable: React.FC<TableProps> = ({
  data, columns
}) => {
  const styles = useStyles();
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key} className={styles.th}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className={styles.row}>
              {columns.map((column) => (
                <td key={column.key} className={styles.td} style={column.key === 'status'
                  ? {
                    color: row.color
                  }
                  : {
                  }}
                >
                  {row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveTable;
