import dayjs from "dayjs";
import {
  useCallback, useEffect, useState
} from "preact/hooks";
// import { useCustomSnackbar } from "src/components/snackbar/custom-snackbar";
import KBFetch from "src/network/api";
import { getURL as getUrl, ROUTES } from "src/network/urls";
import { toIndianCurrencyWithZero } from "src/utils/conversions";

const makeRefundPayload = ({
  toSource = false,
  refundAmount = 0,
  bankDetails = {
  }
}: {
  toSource: boolean;
  refundAmount: number;
  bankDetails: any;
}) => ({
  payment_mode: toSource ? "SOURCE" : "BANK",
  ...(!toSource
    ? {
      bank_details: {
        name: bankDetails.beneficiaryName,
        ifsc: bankDetails.ifscCode,
        account_number: bankDetails.accountNumber,
        type: "savings"
      }
    }
    : {
    }),
  refund_amount: refundAmount,
  refund_status: "INITIATED",
  date: dayjs()
});

const fetchRefunDetails = async () => {
  try {
    const res = await KBFetch(
      ROUTES.GET_REFUND_DETAILS,
      getUrl(ROUTES.GET_REFUND_DETAILS),
      {
        method: "GET",
        headers: {
          "content-type": "application/json"
        }
      }
    ).then((d) => d);
    return res;
  } catch (error) {
    return error;
  }
};

const fetchOrderList = async () => {
  try {
    const res = await KBFetch(
      ROUTES.GET_REFUNABLE_ORDERS,
      getUrl(ROUTES.GET_REFUNABLE_ORDERS),
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "x-kb-app-version": process.env.REACT_APP_VERSION || "000100",
          "x-kb-app-name": "khatabook",
          "x-kb-platform": "web"
        }
      }
    ).then((d) => d);
    return res;
    return {
      refundableOrders: {
        order_details: [
          {
            order_id: "2c1c0b76-2df8-4464-9105-b668779438a4",
            start_date: "2024-07-25T18:29:59.999Z",
            expiry_date: "2025-07-25T18:29:59.999Z",
            tenure_in_days: 365,
            purchase_amount_in_rs: 2,
            remaining_period_in_days: 338,
            refund_amount_in_rs: 1.8520547945205479
          },
          {
            order_id: "8c9998ad-eb85-4ad0-b215-306962918535",
            start_date: "2025-07-27T18:29:59.999Z",
            expiry_date: "2026-07-27T18:29:59.999Z",
            tenure_in_days: 365,
            purchase_amount_in_rs: 2,
            remaining_period_in_days: 365,
            refund_amount_in_rs: 2
          },
          {
            order_id: "2779d271-339a-4e5d-be30-d74afdc5ad3b",
            start_date: "2026-07-28T18:29:59.999Z",
            expiry_date: "2027-07-28T18:29:59.999Z",
            tenure_in_days: 365,
            purchase_amount_in_rs: 2,
            remaining_period_in_days: 365,
            refund_amount_in_rs: 2
          }
        ],
        total_refund_amount_in_rs: 6
      }
    };
    // return res;
  } catch (error) {
    return error;
  }
};

const postRefundDetails = async (payload: any) => {
  try {
    const body = JSON.stringify(payload);
    const res = await KBFetch(
      ROUTES.POST_REFUND_DETAILS,
      getUrl(ROUTES.POST_REFUND_DETAILS),
      {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body
      }
    ).then((d) => d);
    return res;
  } catch (error) {
    return error;
  }
};

const getOrdersListData = (orderList: any) => {
  return orderList.reduce((acc: any, item: any) => {
    return [
      ...acc,
      {
        startAndEndDate: `Subscription (${dayjs(item.start_date).format(
          "DD MMM YYYY"
        )} to ${dayjs(item.end_date).format("DD MMM YYYY")})`,
        lastInvoiceAmount: toIndianCurrencyWithZero(item.purchase_amount_in_rs),
        subscriptionPeriod: `${item.tenure_in_days} days`,
        unusedDays: `${item.remaining_period_in_days} days`,
        unusedAmount: toIndianCurrencyWithZero(item.refund_amount_in_rs)
      }
    ];
  }, []);
};

const useRefundActions = () => {
  const [ordersList, setOrdersList] = useState();
  const [totalRefundAmount, setTotalRefundAmount] = useState(0);
  const [view, setView] = useState("");
  // const { showSnackbar } = useCustomSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [paymentMode, setPaymentMonde] = useState('');

  const initData = async () => {
    try {
      setLoading(true);
      const res = await fetchRefunDetails();

      if (!res.refund_status) {
        setView("DEFAULT");
        const ordersListRes = await fetchOrderList();
        const orders = ordersListRes?.refundableOrders?.order_details || [];
        const totalRefundAmount =
          ordersListRes?.refundableOrders?.total_refund_amount_in_rs || 0;
        setTotalRefundAmount(totalRefundAmount);

        setOrdersList(getOrdersListData(orders));
      } else if (res.refund_status === "INITIATED") {
        setTotalRefundAmount(res.refund_amount);
        setView("INITIATED");
      } else {
        setTotalRefundAmount(res.refund_amount);
        setView("REFUNDED");
      }

      if (res.payment_mode) {
        setPaymentMonde(res.payment_mode);
      }
    } catch (error) {
      // showSnackbar({
      //   type: "error",
      //   message: "Couldnt fetch refund details",
      // });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await initData();
    })();
  }, []);

  const saveRefundPreferences = useCallback(
    async ({
      toSource, bankDetails
    }: any) => {
      const payload = makeRefundPayload({
        toSource,
        bankDetails,
        refundAmount: totalRefundAmount
      });

      try {
        const res = await postRefundDetails(payload);
        // showSnackbar({
        //   type: "success",
        //   message: "Refund preference has been successfully saved",
        // });
        initData();
        return res;
      } catch (error) {
        // showSnackbar({
        //   type: "error",
        //   message: "There was a problem posting refund details",
        // });
      }
    },
    [totalRefundAmount]
  );

  return {
    view,
    totalRefundAmount,
    ordersList,
    saveRefundPreferences,
    isLoading,
    paymentMode
  };
};

export default useRefundActions;
