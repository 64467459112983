import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "16px auto"
  },
  label: {
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: "8px"
  },
  input: {
    padding: "12px",
    fontSize: "14px",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
    width: "93%",
    background: "white",
    color: 'black'
  },
  inputError: {
    border: "1px solid #DC343B"
  },
  errorMessage: {
    color: "#DC343B",
    fontSize: "12px",
    marginTop: "4px"
  },
  required: {
    color: "#DC343B",
    marginLeft: "2px"
  },
  inputField: {
    display: "flex",
    alignItems: "baseline",
    flexDirection: "column"

  }
}));

export default useStyles;
