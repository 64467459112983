/* eslint-disable @typescript-eslint/no-explicit-any */
// libs
// import { isValidNumber } from 'src/utils/functions/number';
// import { getFirebaseApp } from '../firebase'
import { KBUser } from 'src/context/utils';
import { EVENTS/* , FirebaseEvents */ } from './constants';
import { RudderstackAnalytics } from './rudderstack';
import { nextTick } from 'src/utils';
// import { FirebaseAnalytics } from './firebase'

export const KBDeviceName = 'mweb';

// var latest = 1
declare global {
  interface Window {
    analytics: any
    rudderanalytics: any
    dataLayer: { [key: string]: string | number | boolean | object }[]
    confirmationResult: any
    recaptchaVerifier: any
    clevertap: any
    bowser: any
    DesktopSubscriptionWebViewHandler: {
      getHost: () => string
      getCredentials: () => string
      onPaymentSuccess: () => void
      onPaymentFailure: () => void
      onPaymentPending: () => void
      onDismissWebView: () => void
      onContactUsClicked: (contact: string) => any
    }
  }
}

const rudderAnalytics = new RudderstackAnalytics();
// const firebaseApp = getFirebaseApp()
// const firebaseAnalytics = new FirebaseAnalytics(firebaseApp)

export const initializeAnalytics = () => {
  rudderAnalytics?.initialize();
  // firebaseAnalytics?.initialize();
};

let localUser: KBUser | undefined;
export const setUserInAnalytics = async (user: KBUser) => {
  // if we have already set userId and it is same as local
  // avoid refiring GTM event
  if (localUser?.user_id === user.user_id) {
    return;
  }
  if (!user.user_id) return;
  if (!Object.keys(commonEventProperties).length) {
    const props = fetchEventProps(user);
    setCommonEventProperties(props);
  }

  localUser = user;
  // set userId
  rudderAnalytics?.identifyUser(user.user_id, commonEventProperties);

  // set userId on gtm
  // window.dataLayer.push({ event: 'SET_USER', userId })
};

export const unsetUserInAnalytics = () => {
  const userId = '';
  // set userId
  // segmentAnalytics?.identifyUser(userId);
  rudderAnalytics?.identifyUser(userId);

  // set userId on gtm
  window.dataLayer.push({
    event: 'SET_USER', userId
  });
};

let commonEventProperties: any = {
};

const setCommonEventProperties = (props: object) => {
  commonEventProperties = {
    ...commonEventProperties,
    ...props
  };
};

export const fetchEventProps = (user?: KBUser) => {
  const _user = user || localUser;
  const props = {
    is_logged_in: !!_user?.token,
    khatabook_user_id: _user?.user_id || null,
    userId: _user?.user_id || null,
    deviceId: _user?.device_id || null,
    version_code: process.env.ENV_VERSION || null,
    kb_device: KBDeviceName || null,
    phone: _user?.phone || null
  };
  return props;
};

const areCommonEventPropertiesPopulated = () => {
  const propVals = Object.values(commonEventProperties);
  if (propVals.length === 0) return false;
  const areAllPropsSet = propVals.every((x) => Boolean(x) === true);
  return areAllPropsSet;
};

export const sendEvent = async (
  event: EVENTS,
  data: {
    [key: string]: string | number | boolean | object | null | undefined
  } = {
  }
) => {
  if (!areCommonEventPropertiesPopulated()) {
    const props = fetchEventProps();
    setCommonEventProperties(props);
  }
  const userProperties = commonEventProperties;

  const payload: { [key: string]: string | number | boolean | object | null | undefined} = {
    ...data,
    kb_device: KBDeviceName,
    version_code: process.env.ENV_VERSION,
    user_id: userProperties.khatabook_user_id
  };

  const userTraits = rudderAnalytics.getUserTraits();
  const updatedEventName = event;
  if (userTraits && !Object.keys(userTraits).length) {
    rudderAnalytics?.identifyUser(
      commonEventProperties?.userId || '',
      commonEventProperties
    );
  }

  nextTick(() => {
    rudderAnalytics?.trackEvent(updatedEventName, payload);
  });
  if (cleverTapEventMap.find((e) => e === updatedEventName)) {
    let ctPayload = payload;
    if (
      updatedEventName.includes(EVENTS.LoginSuccessPageView) &&
      localUser?.phone
    ) {
      const ctPhone = `+91${localUser?.phone}`;
      localUser?.phone && window.clevertap?.onUserLogin?.push &&
        window.clevertap?.onUserLogin?.push({
          Site: {
            Phone: ctPhone,
            Identity: commonEventProperties?.userId,
            'MSG-sms': ctPhone,
            'MSG-whatsapp': ctPhone
          }
        });
      ctPayload = {
        ...ctPayload, phone: localUser.phone
      };
    }
    window.clevertap.event.push(updatedEventName, ctPayload);
  }
};

export const cleverTapEventMap = [
  EVENTS.CheckoutContinueClick,
  EVENTS.MWebLoginPageView,
  EVENTS.LoginSuccessPageView
];

export const resetAnalytics = () => {
  rudderAnalytics.reset();
};

/* export const sendFirebaseEvent = async (
  event: FirebaseEvents,
  data?: {
    [key: string]: string | number | boolean | object | null | undefined
  }
) => {
  if (!areCommonEventPropertiesPopulated()) {
    const props = await fetchEventProps();
    setCommonEventProperties(props);
  }
  const eventProperties: object = data || {};
  const commonProperties: object = commonEventProperties;
  firebaseAnalytics?.logEventToFirebase(
    event,
    commonProperties,
    eventProperties
  );
}; */
