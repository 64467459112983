import { h } from 'preact';
import useStyles from "./style";

const Banner = () => {
  const styles = useStyles();
  return (
    <div className={styles.banner}>
      You will continue to see your data till <b>31st July 2025</b>
    </div>
  );
};

export default Banner;
